@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  overflow: hidden;
}

:root {
  --expert-plus: #8f48db;
  --expert: #bf2a42;
  --hard: tomato;
  --normal: #59b0f4;
  --easy: MediumSeaGreen;
  font-size: 1vw;
}
